(function($) {
    "use strict";
    $(window).on('scroll', function() {
        var scroll = $(window).scrollTop();
        if (scroll < 400) {
            $("#sticky-header").removeClass("sticky");
            $('#back-top').fadeIn(500);
        } else {
            $("#sticky-header").addClass("sticky");
            $('#back-top').fadeIn(500);
        }
    });

    $(document).ready(function() {
        // mobile_menu
        var menu = $('ul#navigation');
        if (menu.length) {
            menu.slicknav({
                prependTo: ".mobile_menu",
                closedSymbol: '+',
                openedSymbol: '-'
            });
        };
    });
})(jQuery);

function sleep(ms) {
    return new Promise(resolve => setTimeout(resolve, ms));
}

async function sendMail(contactName, contactEmail, contactMessage) {

    var name_value = document.getElementById('arcf-name').value;
    var email_value = document.getElementById('arcf-email').value;
    var msg_value = document.getElementById('arcf-message').value;
    if (name_value == '' || email_value == '' || msg_value == '') {
        alert('Please ensure you have filled out all fields before submitting!');
    } else {
        contactName = encodeURI(contactName);
        contactEmail = encodeURI(contactEmail);
        contactMessage = encodeURI(contactMessage);
        var xhttp = new XMLHttpRequest();
        xhttp.onreadystatechange = function() {
            if (this.readyState == 4 && this.status == 200) {}
        };
        xhttp.open("POST", 'https://7gservices.7g.com.au/ExternalApi/SendWebsiteContactEmail?contactName=' + contactName + '&contactEmail=' + contactEmail + '&contactMessage=' + contactMessage, true);
        xhttp.setRequestHeader("Content-type", "application/json");
        xhttp.setRequestHeader("x-api-version", "1.1.ExternalAPI");
        xhttp.send("");
        await sleep(1000);
        window.location.href = '/thanks';
    }
}

window.onload = function() {
    document.addEventListener("contextmenu", function(e) {
        e.preventDefault();
    }, false);
    document.addEventListener("keydown", function(e) {

        if (e.ctrlKey && e.shiftKey && e.keyCode == 73) {
            disabledEvent(e);
        }

        if (e.ctrlKey && e.shiftKey && e.keyCode == 74) {
            disabledEvent(e);
        }

        if (e.keyCode == 83 && (navigator.platform.match("Mac") ? e.metaKey : e.ctrlKey)) {
            disabledEvent(e);
        }

        if (e.ctrlKey && e.keyCode == 85) {
            disabledEvent(e);
        }

        if (event.keyCode == 123) {
            disabledEvent(e);
        }
    }, false);

    function disabledEvent(e) {
        if (e.stopPropagation) {
            e.stopPropagation();
        } else if (window.event) {
            window.event.cancelBubble = true;
        }
        e.preventDefault();
        return false;
    }
}